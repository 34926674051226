// WRITE ARTICLE
export const declassifyDatapoints = {
  order: 2,
  name: "Declassify datapoints",
  subcategory: "Safezone",
  markdown: `# Declassify datapoints

  ## From a datapoint

  ## From the Safezone tab`,
};
